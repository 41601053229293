.btn-none {
  background-color: $gray-760;
  border-color: $gray-760;
  box-shadow: none;

  &:hover {
    background-color: $gray-760;
    border-color: $gray-760;
  }
}

.btn-bg-dark {
  background-color: $gray-870 !important;
  border-color: $gray-870 !important;

  &:hover {
    background-color: $gray-780 !important;
    border-color: $gray-780 !important;
  }
  
}

//Buttons
.btn {
  position: relative;
  transition: $transition-base;
  // background-color: $gray-520;

  &:hover {
    @include box-shadow($btn-hover-box-shadow);
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -o-transform: translateY(-1px);
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }
}
.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
  &.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
  }
  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 23px;
  }
  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
  }
}

.btn-rounded {
  border-radius: $rounded-pill;
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
  &.btn-lg {
    padding: 0.75rem 1.5rem;
  }
  &.btn-sm {
    padding: 0.25rem 0.75rem;
    font-size: 12px;
  }
  &.btn-md {
    padding: 10px 35px;
    font-size: 16px;
  }
}
// Brand buttons

@each $color, $value in $brand-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

.button-group .btn {
  margin-bottom: 5px;
  margin-right: 5px;
}

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0px;
}

.btn .text-active {
  display: none;
}

.btn.active .text-active {
  display: inline-block;
}

.btn.active .text {
  display: none;
}


/**
 *  로그인 버튼
 */

.btn-login {
  border: none;
  color: $primary;
  background-color: $white;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;

  &:hover {
    color: $white;
    background-color: $primary;
    @include box-shadow($btn-hover-box-shadow);
    transform: none;
    transition: none;
    // transform: translateY(-1px);
    // -webkit-transform: translateY(-1px);
    // -moz-transform: translateY(-1px);
    // -o-transform: translateY(-1px);
  }

}

/**
 *  사이드바 대회 개최하기 이미지 버튼
 */
.btn-competition-open {
  position: relative;
  padding: 0 !important;
  border: 0 !important;
  margin-top: 40px;
  background: url('../../../images/sidebar/competition-open.png') no-repeat;
  background-size: contain;
  width: 100%;
  height: 100px;
  transition: none;
  cursor: pointer;

  .f-location {
    position: absolute;
    top: 19px;
    right: 46px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 0.2px;

    &:hover {
      color: $primary;
    }
  }
}

/**
 * 대회 개최하기(대회방식 / 참여단위)
 */

.btn-group > .btn-light-primary {
  background-color: $gray-730;
  color: $text-muted;
  border-color: $text-muted;
  border-radius: 0;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active  {
    background-color: $gray-100;
    color: $primary;
  }
}
