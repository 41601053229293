// ======================================================
// Custom layout variables
// ======================================================
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap');

// ======================================================
// Theme Global setting colors
// ======================================================

$themecolor: #ed7422;
$themecolor-dark: #028ee1;
$themecolor-alt: #26c6da;
$theme-light: #fff;
$theme-dark: #222327;

$skin1: #2cabe3;
$skin2: #080809;
$skin3: #ff7676;
$skin4: #a0aec4;
$skin5: #222327;
$skin6: #222326;

// ======================================================
// Topbar settings
// ======================================================

$topbar: $theme-light;
$topbar-height: 60px;
$topbar-navlink-padding: 0px 14px;
$topbar-navlink-font-size: 0.875rem;
$topbar-navlink-height: 60px;
$topbar-navbrand-padding: 0 10px 0 10px;
$topbar-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
$topbar-mailbox-width: 300px;
$topbar-mailbox-height: 317px;

// ======================================================
// Authentication backgrounds
// ======================================================

// ======================================================
// Sidebar settings
// ======================================================

// sidebar type
$sidebar-width-full: 250px;
$sidebar-width-iconbar: 180px;
$sidebar-width-mini: 0;
$left-part-width: 260px;
$mobile-header-height: 46px;

// sidebar text color settings
$sidebar: $theme-light;
$sidebar-alt: #e8eff0;
$sidebar-text: #77787b;
$sidebar-icons: #fff;
$sidebar-text-dark: #212529;
$sidebar-icons-dark: #4f5467;

// ======================================================
// boxed layout setting
// ======================================================
$boxed-width: 1200px;

// ======================================================
// Extra style setting
// ======================================================
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
$transitions: 0.2s ease-in;
$transparent-dark-bg: rgba(0, 0, 0, 0.03);
$lft: left;
$rgt: right;
$card-alt: #e4e9ef;

// ======================================================
// Mixin setting
// ======================================================
%square {
  border-radius: 0px;
}

%rotate45 {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

%topbar-shadow {
  box-shadow: $topbar-shadow;
  -webkit-box-shadow: $topbar-shadow;
  -o-box-shadow: $topbar-shadow;
  -moz-box-shadow: $topbar-shadow;
}

%card-shadow {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
}

// ======================================================
// Bootstrap override colors
// ======================================================

$white: #fff !default;
$gray-100: #f7fafc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-520: #9f9fa0  !default; // muted
$gray-600: #8898aa !default;
$gray-610: #787a7e !default;
$gray-620: #77787b !default;
$gray-640: #6e6e6e !default;
$gray-700: #525f7f !default;
$gray-730: #595a5d !default;
$gray-740: #58595c !default;
$gray-760: #454649 !default;
$gray-770: #393a3c !default;
$gray-780: #363739 !default;
$gray-800: #313131 !default;
$gray-850: #2c2d2f  !default;
$gray-870: #222327 !default;
$gray-900: #030303 !default;
$gray-950: #1b1c1e !default; 
$primary: #ed7422 !default;
$black: #000 !default;
$cyan: #4fc3f7 !default;
$red: #ff5353 !default;
$green: #2cd07e !default;
$purple-light: #843daa !default;
$purple: #510c76 !default;
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $gray-760,
    'success': $green,
    'info': $gray-850,
    'warning': $primary,
    'danger': $red,
    'light': $gray-100, 
    'lightDark': #616266,
    'dark': $gray-950,
    'cyan': #4fc3f7,
    'orange': #ffc36d,
    'purple': $purple,
    'inverse': #4c5667,
  ),
  $theme-colors
);

// ======================================================
// Dark Theme Colors Settings
// ======================================================
$theme-dark-bg-layout: #323743;
$theme-dark-text-color: #b2b9bf !default;
$theme-dark-border-color: rgba(255, 255, 255, 0.2) !default;
$theme-dark-focus-border-color: rgba(255, 255, 255, 0.4) !default;
$theme-dark-card-bg: #272b34;
$theme-dark-bg-light: #3c424e;
$main-body-bg-dark: $gray-900 !default;
$background-alt: #2b2b2b !default;

// ======================================================
// Brand Colors
// ======================================================
$brand-colors: () !default;
$brand-colors: map-merge(
  (
    'facebook': #3b5999,
    'twitter': #1da1f2,
    'googleplus': #dd4b39,
    'instagram': #e4405f,
    'pinterest': #bd081c,
    'youtube': #cd201f,
    'linkedin': #007bb6,
    'slack': #3aaf85,
    'dribbble': #ea4c89,
    'github': #222222,
  ),
  $brand-colors
);

// ======================================================
// Enable optional
// ======================================================
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$link-hover-decoration: none !default;

// ======================================================
// Shadow setting
// ======================================================
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.08) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

// ======================================================
// Body
// ======================================================
$main-body-bg: #fff !default;
$body-bg: #363739;
$body-color: #fff !default;

// ======================================================
// Typography
// ======================================================

$font-size-base: 0.875rem;
// $font-family-sans-serif: 'Rubik', sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;
$font-weight-base: $font-weight-normal !default;

// display setting
$display5-size: 3rem !default;
$display6-size: 2.5rem !default;
$display7-size: 2rem !default;
$display5-weight: 300 !default;
$display6-weight: 300 !default;
$display7-weight: 300 !default;

// heading fonts setting
$h1-font-size: 36px !default;
$h2-font-size: 24px !default;
$h3-font-size: 21px !default;
$h4-font-size: 19px !default;
$h5-font-size: 1rem !default;
$h6-font-size: 1rem !default;
$headings-margin-bottom: (1rem / 2) !default;
$headings-font-weight: 400 !default;
$headings-color: inherit !default;

// --------------------------------------------------------------------- //
//              Bootstrap Component override setting
// --------------------------------------------------------------------- //

// Breadcrumbs
$breadcrumb-bg: $body-bg;
$breadcrumb-margin-bottom: 1.5rem;

// Cards
$card-border-width: 0px !default;
$card-border-color: transparent !default;
$card-border-radius: 0px !default;
$card-bottom-margin: 30px !default;
$card-title-size: 16px;
$card-subtitle-size: 14px;
$card-spacer-x: 1.57rem !default;
$card-background-color: $gray-760 !default;

// Dropdowns
$dropdown-item-padding-x: 1rem !default;
$dropdown-item-padding-y: 0.65rem !default;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;
$dropdown-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15),
  0 5px 15px rgba(0, 0, 0, 0.1);

// Buttons
$btn-secondary-border: $gray-300;
$btn-hover-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

// Tables
$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;
$table-hover-bg: $purple-light !default;
$table-cell-padding: 0.9375rem 0.4rem !default;
$table-bg-level: -10 !default;
$table-bg-level2: 1 !default;
$table-bg-level3: -5 !default;

$table-thead-th-color: $gray-520;
$table-font-size: $h5-font-size;
$table-thead-th-font-size: $h4-font-size;


$table-font-weight-normal: $font-weight-light !default; 
$table-font-weight-bold:   $font-weight-normal !default; 

// Components
$component-active-color: $white !default;
$component-active-bg: $themecolor !default;

// Badges
$badge-font-size: 73% !default;
$badge-font-weight: $font-weight-light !default;
$badge-padding-y: 0.35em !default;
$badge-padding-x: 0.7em !default;
$badge-pill-padding-x: 0.7em !default;
$badge-pill-padding-y: 1em !default;

// Forms
$input-group-addon-bg: $gray-100;
$input-border-color: $gray-620;
$input-group-addon-border-color: $gray-200;
$input-btn-focus-color: rgba(0, 0, 0, 0.25) !default;
$input-focus-border-color: rgba(0, 0, 0, 0.25) !default;
$input-btn-focus-box-shadow: none !default;
$input-alternative-box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
$input-focus-alternative-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
$input-box-shadow: none !default;
$input-focus-box-shadow: none !default;
$custom-select-box-shadow: none !default;

// Forms - PlayPot 추가
$input-bg: $gray-520;
$input-color: $white !default;
$border-bg-disable: $gray-850;
$input-disabled-border: $gray-730 !default;
$input-disabled-color: $gray-730 !default;
$input-border-width: 1px;
$input-placeholder-color: $gray-730;

// Border settings
$border-width: 1px !default;
$border-color: $gray-730 !default;
$border-radius: 5px !default;
$border-radius-lg: 7px !default;
$border-radius-sm: 1px !default;

// Progress bars
$progress-height: 6px !default;
$progress-bg: $gray-100;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xlg: 1600px,
) !default;

// Navbar
$navbar-dark-color: rgba($white, 0.9) !default;
$navbar-dark-hover-color: rgba($white, 1) !default;

// Modal
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.2) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.2) !default;

// Tabs
$nav-tabs-link-active-bg: $primary !default;
$nav-tabs-border-color: $gray-780 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-link-hover-bg: $gray-520 !default;
$nav-tabs-link-hover-border-color: $gray-780 $gray-780 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-100 !default;
$nav-tabs-link-active-border-color: $gray-780 $gray-780 $nav-tabs-link-active-bg !default;
