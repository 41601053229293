.select-user-tabs.nav-tabs {
  border-bottom: none;
  box-shadow: 0 1px 15px $gray-780;
  font-weight: 600;
  font-size: 18px;

  .nav-item {
    margin-bottom: -1px;
    position: relative;
    z-index: 1;
    display: block;
    margin: 0;
    text-align: center;
    flex: 1;
  }
  .nav-link {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 55px;
    background: $gray-760 !important;
    color: rgba($white, 0.6) !important;
    border: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    text-transform: uppercase;
    &:hover {
      color: rgba($white, 0.6);
    }
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    color: rgba($white, 1) !important;
    background: $primary !important;
    /* 탭 아래 화살표 */
    // &::after {
    //   position: absolute;
    //   top: 100%;
    //   left: 50%;
    //   margin-left: -10px;
    //   width: 0;
    //   height: 0;
    //   border: solid transparent;
    //   border-width: 10px;
    //   border-top-color: $primary;
    //   content: '';
    //   pointer-events: none;
    // }
  }
}

.apexcharts-text {
  fill: $text-muted;
}
.apexcharts-gridline,
.apexcharts-xaxis-tick,
.apexcharts-xaxis line {
  stroke: rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
  cursor: pointer;
}

.select-line-tabs.nav-tabs {
  border-bottom: none;

  .nav-item {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0;
    text-align: center;
    flex: 1;
    
  }
  .nav-link {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 55px;
    background: $gray-760 !important;
    color: $text-muted !important;
    border: 0px;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 18px !important;
    border-bottom: 3px solid $gray-740;
    &:hover {
      color: rgba($white, 0.6) !important;
      border-bottom: 3px solid rgba($white, 0.6);
    }
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    color: $primary !important;
    border-bottom: 3px solid $primary !important;
  }
}
