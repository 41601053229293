//! 만렙 수정

 /*============================================================== 
  MaxWidth 지정 
 ============================================================== */

.container-fluid {
  transition: all 0.5s;
}

@media (min-width: 1680px) {
  .container-fluid {
    max-width: 1440px;
  }
}

@media (min-width: 1920px) {
  .container-fluid {
    max-width: 1540px;
  }
}

@media (min-width: 2500px) {
  .container-fluid {
    max-width: 1840px;
  }
}

 /*============================================================== 
 For all pages 
 ============================================================== */

#main-wrapper {
  width: 100%;
}

.page-wrapper {
  background: $body-bg;
  position: relative;
  transition: 0.2s ease-in;
  display: none;
}

.page-wrapper > .page-content {
  padding: 30px;
  min-height: calc(100vh - 140px);
}

.page-breadcrumb {
  padding: 30px 30px 0px;
}

// PC 
@media (min-width: 1025px) {
  #main-wrapper[data-layout=vertical][data-sidebartype=full] {
    .page-wrapper {
      margin-left: $sidebar-width-full;
    }
  }
}

// TABLET
@media (min-width: 767.98px) and (max-width: 1024px) {
  .page-wrapper {
    margin-left: 0;
  }
}


// PC & TABLET
@media (min-width: 767.98px) {
  #main-wrapper[data-layout=vertical][data-sidebartype=full] {
    .sidebar {
      width: $sidebar-width-full;
    }
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] {
    .sidebar {
      width: 0;
      overflow: hidden;
    }
  }
}

// MOBILE
@media (max-width: 767.98px) {
  #main-wrapper {
    .sidebar {
      padding-top: $mobile-header-height + 10px;
      width: 0;
      overflow: hidden;
    }
  }

  #main-wrapper.show-sidebar {
    .sidebar {
      width: $sidebar-width-full;
    }
  }
}

/*******************
 competition Card
*******************/
.competition-card-group-wrapper {
  background-color: $gray-760;
  padding-top: 10px;
  min-height: 586px;

  .competition-card {
    padding: 0 40px;
    padding-top: 20px;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 20px;
    }
  }

  .competition-col {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $gray-740;

    .text-box {
      margin-left: 15px;
    }
  }

}

.competition-border-top {
  border-top: 1px solid $gray-740;
  padding-top: 20px;
}

.competition-border-bottom {
  border-bottom: 1px solid $gray-740;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

/*******************
 대회 메인 이미지 
*******************/
.background-shadow {
  position: relative;
  color: #fff;
  // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  box-sizing: border-box;
  // background-color: rgb(23, 24, 26 );
  position: relative;
  overflow: hidden;
  min-height: 21rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.background-shadow::before{
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

/*******************
 팀 메인 이미지
*******************/
.background-banner {
  width: 100%;
  height: 21rem;
  overflow: hidden;
  min-height: 21rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}


/*******************
 Footer
*******************/

.footer {
  position: relative;
  padding: 25px;
  background-color: $primary;
  color: $gray-870;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;

  .underline {
    color: $gray-870;
    padding-bottom: 3px;
    border-bottom: 2px solid $gray-870;
    font-size: 13px !important;
    font-weight: 600 !important;

    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }

  .logo-content {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .logo {
      background: url('../../../../../images/logos/logo-black-1.png') no-repeat;
      background-size: contain;
      width: 40px;
      height: 40px;
    }

    .logo-text {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 600;
      color: $gray-870;
    }
  }

  .box {
    display: flex;
  }

  .line {
    color: $body-bg;
    font-size: 10px;
    font-weight: 100;
    padding: 0 5px;
  }

  .copyright {
    display: inline-block;
    color: $body-bg;
    font-size: 10px;
    font-weight: 400;
  }

}

// 대회카테고리 NavBar (메인/진행/참가자/대진표) - 모바일 일 때
.competition-nav-default {
  position: initial;
}

// 대회카테고리 NavBar (메인/진행/참가자/대진표) - sidebar 존재 하지 않을 때
.competition-nav-wide {
  position: fixed;
  top: 0;
  width: 97%;
  z-index: 2;
  margin-top: 0 !important;
  
  @media (min-width: 450px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 87%;
    flex: 0 0 87%;
    max-width: 87%;
    top: 3rem;
  }

  @media (min-width: 500px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91%;
    flex: 0 0 91%;
    max-width: 91%;
    top: 3rem;
  }
  

  @media (min-width: 768px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 97%;
    flex: 0 0 97%;
    max-width: 97%;
    top: 0;    
  }

} 

// 대회카테고리 NavBar (메인/진행/참가자/대진표) - sidebar 존재 할 때
.competition-nav-sidebar {
  position: fixed;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  z-index: 3;

  & .nav-link {
    height: 46px !important;
  }
}

#main-wrapper[data-sidebartype=mini-sidebar] {
  .competition-nav-sidebar {
    left: 46px;
    width: calc(100% - 46px);
  }
}